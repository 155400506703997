import React from 'react'
import "../components/Dashboard.css"
import Maps from '../components/Maps.js'
// import Devices from './Devices'
// import Map from './Map'
// import Voice from './Voice'


function Dashboard() {
  return (
    <div className='dashboard'>
      {/* <div className='dashboard_Left'>
        <Devices/>
        <Map/>
        <Voice/>   
      </div> */}
      <div className='dashboard_Right'>
        <Maps/>
      </div>
    </div>
  )
}

export default Dashboard
