import React from 'react'
import Navbar from '../components/Navbar'
import Cameras from '../components/Cameras'
import "../components/Cameras.css"


function CamerasScreen() {
  return (
    <div className='camerasScreen'>
        <Navbar/>
        <div className='cameras_Dashboard'>
            <Cameras/>
        </div>
    </div>
  )
}

export default CamerasScreen
