export const setVideoCSS = (gridSize) => {
    const videoElements = document.querySelectorAll('.camera > video');
    const gridHeightMap = {
      1: '90vh',
      4: '45vh',
      9: '30vh',
      12: '30vh',
      16: '22.5vh',
    };

    // O grid de 9 na verdade é o grid de 6
  
    videoElements.forEach((video) => {
      video.style.height = gridHeightMap[gridSize] || gridHeightMap.default;
    });
  };
  