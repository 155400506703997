export const criarElementoDeVideo = (idDaDiv) => {
  const video = document.createElement("video");
  video.id = "remoteVideo-" + idDaDiv;
  video.controls = true;
  video.muted = true;
  video.autoplay = true;

  const style = document.createElement("style");
  style.textContent = `
      video::-webkit-media-controls-play-button { display: none; }

      video::-webkit-media-controls-volume-slider { display: none; }

      video::-webkit-media-controls-mute-button { display: none; }

      video::-webkit-media-controls-current-time-display { display: none; }
    `;
  document.head.appendChild(style);

  const videoContainer = document.createElement("div");
  videoContainer.className = "camera";
  videoContainer.appendChild(video);

  return videoContainer;
};
