import React, { useState, useEffect } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import "../components/Inferences.css";
import Draggable from 'react-draggable';
import axios from 'axios';



function Inferences() {
  const [activeUserId, setActiveUserId] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchActiveUserId = async () => {
        try {
            const userData = JSON.parse(sessionStorage.getItem('user'));
            if (userData) {
                setActiveUserId(userData.id_user);
            }
        } catch (error) {
            console.error('Error fetching active user ID:', error);
        }
    };

    fetchActiveUserId();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if(activeUserId){
          const response = await axios.get(`https://bifrost.spyskytech.com/${activeUserId}/grouped_analysis`);
          const responseData = response.data
          setData(responseData);
        }
      } catch (error) {
        console.error('Error fetching inferences:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up an interval to fetch data periodically
    const interval = setInterval(fetchData, 5000); // Fetch every 5 seconds
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [activeUserId]);

  const processChartData = (data) => {
    const chartData = {
      labels: [],
      quantities: []
    };
    const counts = {};
    data.devices?.forEach(device => {
      device.object_counts.forEach(countObj => {
        if (counts[countObj.class_name]) {
          counts[countObj.class_name] += countObj.count;
        } else {
          counts[countObj.class_name] = countObj.count;
        }
      });
    });

    for (const className in counts) {
      chartData.labels.push(className);
      chartData.quantities.push(counts[className]);
  }

  return chartData;
  
  };

  const chartData = data ? processChartData(data) : { labels: [], quantities: [], confidences: [] };

  const lineChartData = {
    type: 'line',
    labels: chartData.labels,
    datasets: [
      {
        label: '',
        data: chartData.quantities,
        backgroundColor: [
          '#fff	',
          '#C71585	',
          '#00BFFF	',
          '#87CEFA	',
          '#FFD700	',
          '#4169E1',
          '#BC8F8F	',
          '#B0C4DE	',
          '#179BAE',
          '#00CED1	',
          '#DAA520	',
          '#BA55D3	',
          '#FFC0CB	',
          '#FFDEAD	'

        ],
        borderWidth: 0,
        pointRadius: 5, 
        pointHoverRadius: 8,  
      }
    ]
  };


 const LineChartOptions = {
  plugins: {
    legend: {
      display: true,
      labels: {
        generateLabels: (chart) => {
          const data = chart.data;
          return data.datasets
            .map((dataset, i) => {
              if (dataset.label && dataset.label.trim() !== '') {
                return {
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor[i],
                  hidden: !data.datasets[i].hidden,
                  lineCap: 'butt',
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: 'miter',
                  strokeStyle: '#fff',
                  pointStyle: 'circle',
                  rotation: 0
                };
              }
              return null; // Ignora datasets com label vazio
            })
            .filter(item => item !== null); // Remove itens nulos
        }
      }
    },
    tooltip: {
      enabled: true,  // Habilita o tooltip
      callbacks: {
        label: function(context) {
          const label = context.dataset.label || '';
          const value = context.raw || '';
          return `${label}: ${value}`;
        }
      }
    }
  },
  
  scales: {
    x: {
      ticks: {
        color: 'white'
      },
      grid: {
        color: 'rgba(110, 110, 110, 1)'
      }
    },
    y: {
      ticks: {
        beginAtZero: true,
        color: 'white'
      },
      grid: {
        color: 'rgba(110, 110, 110, 1)'
      }
    }
  }
};
  
  
  const pieChartData = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.quantities, // Use quantities for pie chart data
        backgroundColor: [
          '#fff	',
          '#C71585	',
          '#00BFFF	',
          '#87CEFA	',
          '#FFD700	',
          '#4169E1',
          '#BC8F8F	',
          '#B0C4DE	',
          '#179BAE',
          '#00CED1	',
          '#DAA520	',
          '#BA55D3	',
          '#FFC0CB	',
          '#FFDEAD	'

        ],
        borderWidth: 0,
        width: 20,
        height: 20,
      }
    ]
  };
  const pieChartOptions = {
    plugins: {
      legend: {
        position: 'right', // Alinha a legenda à direita do gráfico
        labels: {
          font: {
            size: 18, // Tamanho da fonte das legendas
            family: 'Arial', // Fonte da legenda (opcional)
          },
          color: '#fff', // Cor da fonte das legendas
          boxWidth: 15, // Largura do quadrado de cor da legenda
          padding: 20, // Espaçamento entre os itens da legenda
          
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <div className='inferences'>
      {data ? (
        <div className='inferences_Content'>
            <Draggable>
            <div className='bar_Chart'>
              <p>Quantidade de Inferências</p>
              <Line data={lineChartData} options={LineChartOptions}/>
            </div>
            </Draggable>
            <Draggable>
            <div className='pie_Chart'>
              <p>Identificação de Elementos</p>
              <Doughnut data={pieChartData} options={pieChartOptions} />

            </div>
            </Draggable>
          </div>
      ) : (
        <p>Loading inferences...</p>
      )}
    </div>
  );
}

export default Inferences;
