// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBGdKg1LNVBGYG_yK8ljhOFk_yxmAg9WjM",
  authDomain: "sstplay-6d877.firebaseapp.com",
  projectId: "sstplay-6d877",
  storageBucket: "sstplay-6d877.appspot.com",
  messagingSenderId: "258127631178",
  appId: "1:258127631178:web:6d51fee77a38988faba7b3",
  measurementId: "G-W50D7JGX3Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging Service
const messaging = getMessaging(app);

// const auth = getAuth(app);

const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted'){
    console.log("Calling firebase getToken function...")
    const token = await getToken(messaging, {
      vapidKey: "BBBoRhO03-9fJfI8aevGVC4nb-B8M_HWbV-uj0eO89Gf2OT6fKyToCF0Sa1qNuFSvy2Zv_TtI8TQZFOYUc2Kp1Y"
    })
    console.log("Function returned... ", token)
    return token;
  }
}

export {generateToken, messaging}