import React from 'react'
import Navbar from '../components/Navbar'
import Dashboard from '../components/Dashboard'

function HomeScreen() {
  return (
    <div className='central'>
        <Navbar/>
        <div className='central_Content'>
        <Dashboard/>
        </div>
    </div>
  )
}

export default HomeScreen
