import React from 'react';
import Navbar from '../components/Navbar';
import IframeComponent from '../components/iFrameComponent';

function AiScreen() {
  return (
    <div className='ai'>
      <Navbar />
      <div className='ai_Component'>
        <IframeComponent/>
      </div>
    </div>
  );
}

export default AiScreen;
