import { createContext, useContext, useEffect, useState } from "react";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState({});

  useEffect(() => {
    onMessage(messaging, (payload) => {
      setMessage(payload.notification);
    });
  }, []);

  return (
    <MessageContext.Provider value={{ message }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessages = () => useContext(MessageContext);
