import { WebRTCAdaptor } from "https://cdn.skypack.dev/@antmedia/webrtc_adaptor@SNAPSHOT";
import axios from 'axios';

export const addCamera = async (device, webRTCAdaptors, setCameras, criarElementoDeVideo) => {
  try {
    const antMediaObj = await axios.get(`https://ant.spyskytech.com:5443/WebRTCAppEE/rest/v2/broadcasts/${device.streamid}`);
    
    if (antMediaObj.status === 200) {
      // Criar o elemento de vídeo
      const videoContainer = criarElementoDeVideo(device.streamid);
      
      // Verificar se o elemento de vídeo foi criado com sucesso
      if (videoContainer instanceof HTMLDivElement) {
        if (!webRTCAdaptors.current[device.streamid]) {
          webRTCAdaptors.current[device.streamid] = new WebRTCAdaptor({
            websocket_url: "wss://ant.spyskytech.com:5443/WebRTCAppEE/websocket",
            remoteVideoElement: videoContainer.querySelector('video'),
            mediaConstraints: {
              audio: false,
              video: false,
            },
            callback: (info, obj) => {
              if (info === "initialized") {
                webRTCAdaptors.current[device.streamid].play(device.streamid);
              }
              if (info === "play_started") {
                setCameras((prevCameras) => [...prevCameras, { id: device.streamid, id_device: device.id_device, device_model: device.device_model, device_name: device.device_name, lat: device.lat, long: device.long, status: device.status, videoContainer }]);
                const video = videoContainer.querySelector('video');
                video.addEventListener('canplay', () => {
                  video.play().catch((error) => {
                    console.error('Autoplay error: ', error);
                  });
                });
              }
            },
            callbackError: (error) => {
              console.error("error callback: " + JSON.stringify(error));
              if (error === "no_stream_exist") {
                console.log(`Stream not found for ${device.streamid}`);
              }
            },
          });
        }
      } else {
        console.error('Failed to create video container:', videoContainer);
      }
    } else {
      console.error('Failed to fetch antMediaObj:', antMediaObj);
    }
  } catch (error) {
    console.error('Error checking camera status', error);
  }
};
