
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript, OverlayView } from '@react-google-maps/api';
import { addCamera } from '../functions/webrtcUtils';
import { criarElementoDeVideo } from '../functions/videoUtils';
import axios from 'axios'; 
import "../components/Map.css"
import FilterDevice from "../components/FilterDevice"

const containerStyle = {
    width: '100%',
    height: '100vh'
};

const getPinImageUrl = (status) => {
    return status === 'online'
        ? '../images/icons/mapa/PinCameraIpON.png'
        : '../images/icons/mapa/PinCameraIpOff.png'
};

function Maps() {
    const [, setDevices] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [openStreams, setOpenStreams] = useState([]);
    const webRTCAdaptors = useRef({});
    const [showFilter, setShowFilter] = useState(false);
    const [filterStatus, setFilterStatus] = useState('all');

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyD6xyMDrd62_iPVWn2KvmrGd704aTFi3rg"
    });

    const storedUser = useMemo(() => JSON.parse(sessionStorage.getItem('user')), []);

    const fetchDevices = useCallback(async (status = 'all') => {
        if (!storedUser || !storedUser.id_user) return;
        try {
            let url = `https://dm.spyskytech.com/api/get_devices/${storedUser.id_user}`;
            if (status !== 'all') url += `?status=${status}`;

            const response = await axios.get(url);
            const devicesData = response.data;
            setDevices(devicesData);

            const cameraPromises = devicesData.map(async (device) => {
                if (device.status === 'online') {
                    try {
                        const camera = await addCamera(device, webRTCAdaptors, setCameras, criarElementoDeVideo);
                        if (camera && camera.videoContainer instanceof Node) {
                            return camera;
                        } else {
                            console.error('Invalid camera object:', camera);
                            return null;
                        }
                    } catch (error) {
                        console.error('Error adding camera:', error);
                        return null;
                    }
                } else {
                    return {
                        id: device.streamid,
                        id_device: device.id_device,
                        device_model: device.device_model,
                        device_name: device.device_name,
                        lat: device.lat,
                        long: device.long,
                        status: device.status,
                        videoContainer: null,
                    };
                }
            });

            const cameraResults = await Promise.all(cameraPromises);
            setCameras(cameraResults.filter(camera => camera !== null));
            setFilteredDevices(cameraResults.filter(camera => camera !== null));

        } catch (error) {
            console.log(error);
        }
    }, [storedUser]);

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices, storedUser]);

    useEffect(() => {
        if (filterStatus === 'all') {
            setFilteredDevices(cameras);
        } else {
            const filtered = cameras.filter(camera => camera.status === filterStatus);
            setFilteredDevices(filtered);
        }
        setOpenStreams([]); // Close all open info windows when filtering
    }, [cameras, filterStatus]);
    

    const handleMarkerClick = (index) => {
        setOpenStreams(prevOpenStreams => {
            const isOpen = prevOpenStreams.includes(index);
            if (isOpen) {
                return prevOpenStreams.filter(i => i !== index);
            } else {
                return [...prevOpenStreams, index];
            }
        });
    };

    const handleCloseClick = (index) => {
        setOpenStreams(prevOpenStreams => prevOpenStreams.filter(i => i !== index));
    };

    const calculateMarkerPosition = (camera, index) => {
        const baseOffset = 0.00005; // Adjust the value as necessary for the desired spacing
        const angle = (index / 10) * Math.PI * 2; // Distribute the markers in a circle

        const latOffset = baseOffset * Math.cos(angle);
        const lngOffset = baseOffset * Math.sin(angle);

        return {
            lat: parseFloat(camera.lat) + latOffset,
            lng: parseFloat(camera.long) + lngOffset,
        };
    };

    const handleStatusFilterChange = (status) => {
        setFilterStatus(status);
    };

    if (loadError) return <div>Error loading map</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className='map_View'>
            <div className="filter_Buttons">
                <button onClick={() => handleStatusFilterChange('all')}>All</button>
                <button onClick={() => handleStatusFilterChange('online')}>ON</button>
                <button onClick={() => handleStatusFilterChange('offline')}>OFF</button>
            </div>

            {showFilter && (
                <FilterDevice
                    onClose={() => setShowFilter(false)}
                    onConfirm={(filters) => {
                        const selectedTypes = Object.keys(filters).filter(key => filters[key]);
                        if (selectedTypes.length === 0) {
                            setFilteredDevices(cameras);
                        } else {
                            const filtered = cameras.filter(camera => selectedTypes.includes(camera.device_model));
                            setFilteredDevices(filtered);
                        }
                        setShowFilter(false);
                    }}
                />
            )}

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: -23.56036437857493, lng: -46.64199109550119 }}
                zoom={6}
            >
                {filteredDevices.map((camera, index) => (
                    <React.Fragment key={index}>
                        <Marker
                            position={calculateMarkerPosition(camera, index)}
                            onClick={() => handleMarkerClick(index)}
                            icon={{
                                url: getPinImageUrl(camera.status),
                                scaledSize: new window.google.maps.Size(40, 40) // Adjust the size of the icon as needed
                            }}
                        />
                        {openStreams.includes(index) && (
                            <OverlayView
                                position={calculateMarkerPosition(camera, index)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div className='Info_Camera'>
                                    <button className="close-button" onClick={() => handleCloseClick(index)}>×</button>
                                    <div ref={(el) => {
                                        if (el && el.childNodes.length === 0 && camera.videoContainer instanceof Node) {
                                            el.appendChild(camera.videoContainer);
                                            const video = camera.videoContainer.querySelector('video');
                                            if (video) {
                                                video.play().catch(error => console.error('Autoplay error:', error));
                                            }
                                        }
                                    }} />
                                </div>
                            </OverlayView>
                        )}
                    </React.Fragment>
                ))}
            </GoogleMap>
        </div>
    );
}

export default Maps;

